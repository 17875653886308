import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Content from "../components/content"

export default (): JSX.Element => {
	const graphQl = useStaticQuery(graphql`
		query {
			multiApiSourceApiContent {
				content {
					type
					title
					content
					uuid
					hide
				}
			}
		}
	`)

	const [content, setContent] = React.useState(null)
	React.useEffect(() => {
		fetch(`${process.env.BASEPATH ?? ""}/api/content_en`)
			.then((nonJsonContent) => nonJsonContent.json())
			.then((data) => setContent(data))
	}, [])

	return <>{content && <Content isEnglish data={content.content} />}</>
}
